@font-face {
  font-family: "hk_grotesk";
  src: url("fonts/hkgrotesk-light-webfont.woff2") format("woff2"),
    url("fonts/hkgrotesk-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "hk_grotesk";
  src: url("fonts/hkgrotesk-medium-webfont.woff2") format("woff2"),
    url("fonts/hkgrotesk-medium-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.cursorHidden {
  cursor: none;
}

body {
  font-family: "hk_grotesk", sans-serif;
}
