*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
}

body {
  margin: 0;
  font-family: sans-serif;
  font-size: 1.5rem;
}

input[type="text"] {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 5px;
  color: white;
  border: 1px solid white;
  max-width: 15rem;
}

input[type="range"] {
  -webkit-appearance: none;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  border: 1px solid white;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid white;
  height: 1rem;
  width: 0.5rem;
  border-radius: 3px;
  cursor: pointer;
}

input[type="color"] {
  -webkit-appearance: none;
  border: 1px solid white;
  height: 2rem;
  width: 5rem;
  border-radius: 3px;
  cursor: pointer;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

button {
  border-radius: 5px;
  border: 1px solid white;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

label {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0;
}

input[type="text"],
input[type="password"],
button {
  font-size: 20px;
}

input[type="text"] {
  width: 100%;
}
